
#footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    width: 100vw;
    background: var(--bg2);
    padding: 1rem 3rem;
    transition: 0.75s background-color ease-in-out;
}

#footer p {
    margin-left: .5rem;
    font-family: "Minecraftia";
    color: var(--fg);
    transition: 0.75s color ease-in-out;
}

#footer p em {
    opacity: .5;
}