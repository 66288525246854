@font-face {
    font-family: "Botsmatic";
    src: url("@fonts/BotsmaticRegularDemo.ttf") format("truetype");
}

#container {
    position: absolute;
    /* background: linear-gradient(180deg, #15514E 0%, #164978 97.5%); */
    background: url("@resources/landing/background2.webp") no-repeat fixed;
    background-size: cover;
    background-position: left;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}

#logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

#glass_block {
    position: absolute;
    width: 22rem;
    animation: float2 4s ease-in-out infinite;
}

#text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    animation: float 4s ease-in-out infinite;
}

#title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

#title p {
    color: #87BEFF;
    font-family: "Botsmatic";
    font-size: 8.5rem;
    font-weight: 400;
    margin: 0;
    box-sizing: content-box;
}

#subtitle {
    color: #87BEFF;
    font-family: "Botsmatic";
    font-size: 1.41rem;
    font-weight: 400;
    margin-top: -3rem;
    /* text-shadow: 0 0 0.5rem rgba(255, 255, 255, 0.5); */
}

#navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

#navbar>* {
    transition: transform 0.2s;
}

#navbar>*:hover {
    transform: scale(1.1);
}

#discord {
    width: 5.2rem;
    animation: float-x-left 6s ease-in-out infinite;
}

#eye_of_ender {
    width: 5.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    animation: grow 6s ease-in-out infinite;
}

#instagram {
    width: 5rem;
    animation: float-x-right 6s ease-in-out infinite;
}

/* || Animations */
@keyframes grow {
    0% {
        transform: scale(1.05);
    }

    50% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
    }
}

@keyframes float {
    0% {
        transform: translatey(-4px);
    }

    50% {
        transform: translatey(-9px);
    }

    100% {
        transform: translatey(-4px);
    }
}

@keyframes float2 {
    0% {
        transform: translatey(-3px);
    }

    50% {
        transform: translatey(-5px);
    }

    100% {
        transform: translatey(-3px);
    }
}

@keyframes float-x-left {
    0% {
        transform: translatex(0px);
    }

    50% {
        transform: translatex(-5px);
    }

    100% {
        transform: translatex(0px);
    }
}

@keyframes float-x-right {
    0% {
        transform: translatex(0px);
    }

    50% {
        transform: translatex(+5px);
    }

    100% {
        transform: translatex(0px);
    }
}

/* title letter-wave animation */
#title p {
    animation: wave-text 6s ease-in-out infinite;
    display: inline-block;
}

#title p:nth-of-type(1) {
    animation-delay: 0s;
}

#title p:nth-of-type(2) {
    animation-delay: 0.1s;
}

#title p:nth-of-type(3) {
    animation-delay: 0.2s;
}

#title p:nth-of-type(4) {
    animation-delay: 0.3s;
}

@keyframes wave-text {
    00% {
        transform: translateY(0px);
    }

    90% {
        transform: translateY(0px);
        text-shadow: none;
    }

    95% {
        transform: translateY(-20px);
        text-shadow: 0 0 1rem rgba(146, 196, 217, 0.505);
    }

    100% {
        transform: translateY(0px);
        text-shadow: none;
    }
}

/* || Mobile */
@media screen and (max-width: 700px) {

    #glass_block {
        width: 15rem;
    }

    #title p {
        font-size: 5rem;
    }

    #subtitle {
        font-size: 1rem;
        margin-top: -1.5rem;
    }

    #navbar img {
        width: 4rem;
    }
}