@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* || General */
#container {
    user-select: text;
    box-sizing: border-box;
    padding: 2rem 7.5rem;
    transition: 0.75s background-color ease-in-out;
    background: var(--bg);
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 2rem;
}

.card_group {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;
    width: 100%;
    min-width: 18.5rem;
    max-width: 100rem;
}

.big_card {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
    box-sizing: border-box;
    padding: 2rem 3rem 2rem 3rem;
    min-height: 30rem;
    max-width: 100rem;
    min-width: 18.5rem;
    border-radius: 1.25rem;
    width: 100%;
    row-gap: 0rem;
}

.big_card_content {
    box-sizing: border-box;
    max-width: max(50%, 18.5rem);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.card {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 2;
    width: min-content;
    min-width: 18.5rem;
    box-sizing: border-box;
    padding: 2rem 3rem 3rem 3rem;
    border-radius: 1.25rem;
}

.text_container {
    margin-left: 2rem;
    margin-right: 2rem;
}

.text_container p{
    font-size: min(max(1.1vw, 1rem), 1.125rem);
}

.home h1 {
    color: #1E1E1E;
    font-family: Minecraftia;
    font-size: min(max(2.5vw, 1.8rem), 2.5rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.home h2 {
    color: #1E1E1E;
    font-family: Minecraftia;
    font-size: min(max(1.22vw, 1rem), 1.25rem);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

#card2 h1,
#card3 h1,
#card4 h1,
#donations_card h1 {
    font-size: min(max(2vw, 1.5rem), 2rem);
}

.home p {
    color: rgba(30, 30, 30, 0.70);
    font-family: "DM Sans";
    margin-top: 2rem;
    font-size: min(max(1.1vw, 1rem), 1.125rem);
    font-style: normal;
    line-height: 150.79%;
    margin-bottom: 0;
}

.bold_italic {
    font-style: italic;
    font-weight: 700;
}

.blue_gradient {
    font-weight: 500;
    background: linear-gradient(180deg, rgba(24, 152, 192, 0.70) 75.85%, rgba(0, 1, 30, 0.70) 110.62%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration-line: underline;
    text-decoration-color:rgb(67, 165, 195)
}

.light_blue_gradient {
    font-weight: 500;
    background: linear-gradient(180deg, rgb(24, 153, 192) 75.85%, rgba(245, 245, 245, 0.7) 110.62%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration-line: underline;
    text-decoration-color:rgb(24, 153, 192)
}

/* || Welcome | Card 1 */
#welcome {
    background: linear-gradient(180deg, #B2E0F0 0%, #8CEACE 100%);
}

#bee_area {
    margin-right: 7rem;
    width: 30%;
}

#bee_area #beehive_front {
    position: absolute;
    aspect-ratio: 1/1;
    width: 11rem;
    right: 10.5rem;
    bottom: 4.5rem;
    z-index: 2;
}

#bee_area #grass {
    position: absolute;
    aspect-ratio: 6.1875/7.5625;
    width: 6rem;
    right: 13rem;
    bottom: 12rem;
    z-index: 0;
}

#bee_area #nest {
    position: absolute;
    width: 11.4375rem;
    right: 7rem;
    bottom: 12.5rem;
    filter: saturate(1.1);
    z-index: 1;
}

#bee_canvas {
    min-width: 10rem;
    min-height: 10rem;
    width: 90%;
    height: 85%;
    filter: saturate(1.1);
    animation: float 2s alternate-reverse infinite;
}

.wrap_reverse {
    flex-wrap: wrap-reverse;
}

.image_card {
    max-height: 25rem;
    min-height: 18.5rem;
    max-width: 25rem;
    min-width: 18.5rem;
    aspect-ratio: 1/1;
    border-radius: 1.25rem;
    background-size: cover;
    flex-grow: 1;
}

/* || Events | Card 2: Minion Card */
#card2_img {
    background-image: url("@resources/home/minions.webp");
}

#card2 {
    filter: saturate(1.1);
    background: linear-gradient(180deg, #FFDEB8 0%, #FFFCAD 100%);
}

/* || Events | Card 3: Pikachu Card */
#card3_img {
    background-image: url("@resources/home/pikachu.webp");
}

#card3 {
    background: linear-gradient(180deg, #FFDACA 0%, #FFA4A4 100%);
}

/* || Events | Card 4: Hunger Games Card */
#card4_img {
    background-image: url("@resources/home/cake.webp");
}

#card4 {
    background: linear-gradient(181deg, #B8C8FF 0.83%, #86B6FF 98.9%);
}

/* || Donations | Card 5 */
.half {
    width: 50%;
    min-width: 20rem;
}

.donations_text {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100%;
}

#donations_card {
    filter: saturate(1.1);
    background: linear-gradient(239deg, #DECAFF 9.6%, #B0A3FD 68.94%);
}

#bench_canvas_container {
    min-width: 20rem;
    min-height: 20rem;
    width: 90%;
    height: 90%;
}

/* || CONTACT */
.contact {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
}

.contact h1, .contact h2, .contact p {
    transition: ease-in-out 0.5s;
    color: var(--fg);
    text-align: center;
}

.contact p {
    margin: 1rem 0;
}

#axolotl_canvas_container {
    height: 7.7rem;
    width: 7.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
}

#pane {
    position: absolute;
    opacity: .5;
    aspect-ratio: 1/1;
    width: 7.4375rem;
    z-index: 1;
}

/* || Animations */
@keyframes float {
    0% { transform: translateY(-5px); }
    100% { transform: translateY(-10px); }
}

/* || Mobile */
@media screen and (max-width: 920px){
    .text_container {
        margin-left: 0;
        margin-right: 0;
    }

    #bee_area {
        margin-right: 0;
        width: unset;
    }
    #grass {
        display: none;
    }

    #beehive_front {
        display: none;
    }

    #nest {
        display: none;
    }

    .half {
        width: 40%;
        min-width: 15rem;
    }

    #bench_canvas_container {
        min-width: 15rem;
        min-height: 15rem;
    }

    #container {
        padding: 2rem 2rem;
    }
}