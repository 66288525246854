
html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    user-select: none;
    box-sizing: border-box;
    transition: 0.75s background-color ease-in-out;
    background-color: var(--bg);
}

#root {
    width: 100%;
    height: 100%;
}

