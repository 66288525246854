
#scrollable {
    position: relative;
    height: calc(100vh - var(--navbarHeight));
    top: var(--navbarHeight);
    overflow-y: scroll;
    overflow-x: hidden;
}

#scrollable::-webkit-scrollbar{
    position: absolute;
    display: none;
    width: 5px;
}

#scrollable::-webkit-scrollbar-track {
    background: var(--bg);
}

#scrollable::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 5px;
}