@font-face {
    font-family: "Minecraftia";
    src: url("@fonts/Minecraftia.ttf");
}

:root {
    --bg: #181818;
    --fg: #F5F5F5;
    --bg2: #1E1E1E;
    --themeIcon: url("@resources/global/sun.webp");
    --navbarHeight: 6rem;
    --shadow: 0 0 0.25rem 0.25rem rgba(255, 234, 0, 0.147);
}

[data-theme='light']{
    --bg: #F5F5F5;
    --fg: #181818;
    --bg2: #eaeaea;
    --themeIcon: url("@resources/global/moon.webp");
    --shadow: 0 0 0.2rem 0.2rem rgba(119, 119, 119, 0.147);
}

#navbar {
    position: fixed;
    top: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: var(--bg);
    padding: 1rem 3rem;
    width: 100vw;
    height: var(--navbarHeight);
    box-sizing: border-box;
    transition: 0.75s background-color ease-in-out;
}

#logo {
    margin: 0;
    margin-top: 0.5rem;
    width: 4.5rem;
    flex-shrink: 0;
}

#options {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 3.25rem;
    margin: 0;
    list-style: none;
}

#options li {
    cursor: pointer;
    font-family: "Minecraftia";
}

#options li a {
    transition: 0.5s color ease-in-out, 0.1s opacity ease-in-out;
    text-decoration: none;
    color: var(--fg);
}

#options li a:hover {
    opacity: 75%;
}

#toggle_container {
    cursor: default!important;
    width: 3rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

#toggle {
    cursor: pointer;
    width: 70%;
    aspect-ratio: 1/1;
    transition: 0.75s ease-in-out;
    background-image: var(--themeIcon);
    background-size: cover;
    box-shadow: var(--shadow);
    position: absolute;
}

.reset {
    animation: none;
}

.animating {
    animation: toggle .75s ease-in-out;
}

@keyframes toggle {
    0% {
        top: 17%;
    }

    50% {
        top: 3.5rem;
    }

    100% {
        top: 17%;
    }
}

@media screen and (max-width: 626px){

    .option {
        display: none;
    }
}